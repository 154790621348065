.explore-container {
  .chart {
    height: calc(50% - 20px);
  }

  .data-container {
    height: calc(50% - 20px);
    margin-top: 16px;
    padding-bottom: 8px;
    position: relative;

    .data-card {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      height: calc(50% - 8px);
      justify-content: center;
      margin: 4px 0;
      padding: 8px;
      width: calc(33% - 5px);

      &:nth-child(3n + 2) {
        margin: 4px 8px;
      }

      &.big {
        display: flex;
        position: absolute;
        height: calc(100% - 8px);
        left: -100vw;
        margin: 0;
        width: 100%;
        top: 0;
        transition: left 300ms;

        &.show {
          left: 0;
        }

        .exercise {
          position: absolute;
          font-size: 200px;
          left: -50px;
          opacity: 0.1;
          top: 8px;
        }

        .athletes {
          max-height: calc(100% - 16px);
          overflow-y: auto;

          p {
            align-items: center;
            background-color: white;
            border-radius: 20px;
            border-style: solid;
            border-width: 1px;
            color: white;
            display: flex;
            justify-content: space-between;
            padding: 4px 8px;
            width: 216px;

            &:not(:first-child) {
              margin-top: 8px;
            }

            span {
              &:first-child {
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }

        .close {
          padding: 4px;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }

      p {
        margin: 0;

        &.material-icons {
          font-size: 32px;
          padding: 4px 0;
        }

        &.text {
          align-items: center;
          color: #555;
          display: flex;
          justify-content: center;

          span {
            font-size: 12px;
            margin-left: 2px;
          }
        }
      }
    }
  }
}