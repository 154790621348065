@import './utils/mixins';

.home {
  color: white;
  margin: 0 auto;
  padding: 16px;
  width: 100%;

  .buttons {
    a {
      display: block;
      margin: 16px 0;
      text-decoration: none;
    }
  }

  .go-to-map {
    background-color: $purple;
    color: white;
    margin: 0 auto;
    width: calc(100% - 32px);

    &:hover,
    &:focus,
    &:active {
      background-color: transparentize($purple, 0.4);
    }

    @media (min-width: 800px) {
      width: auto;
    }
  }

  @media (min-width: 800px) {
    width: 800px;
  }
}
